import { DocumentType } from '../../global'

type DocumentUploadDoneEvent = CustomEvent<{
    type: DocumentType
    name: string
}>

export default () => ({
    droppingQuotes: false,
    droppingAttachments: false,

    quotes: [] as File[],
    attachments: [] as File[],

    async uploadQuotes(files: FileList) {
        this.quotes.push(...files)
        for (const file of files) {
            await this.upload(file, 'quote')
        }
    },

    async uploadAttachments(files: FileList) {
        this.attachments.push(...files)
        for (const file of files) {
            await this.upload(file, 'attachment')
        }
    },

    upload(file: File, type: DocumentType): Promise<boolean> {
        return new Promise((resolve, reject) => {
            // @ts-expect-error: Property '$wire' does not exist on type...
            this.$wire.upload(
                type,
                file,
                () => resolve(true),
                () => reject(false)
            )
        })
    },

    handleDocumentUploadDone(event: DocumentUploadDoneEvent): void {
        if (event.detail.type === 'quote') {
            this.quotes = this.quotes.filter(({ name }) => name !== event.detail.name)
        } else {
            this.attachments = this.attachments.filter(({ name }) => name !== event.detail.name)
        }
    },
})
