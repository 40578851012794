import type { AlpineComponent } from 'alpinejs'

type Props = {
    value: string
    successMessage: string
}
type Clipboard = AlpineComponent<{
    textToCopy: string
    copy: () => void
    successMessage: string
}>

const Component = (props: Props): Clipboard => ({
    textToCopy: props.value,
    successMessage: props.successMessage,

    copy(): void {
        window.navigator.clipboard.writeText(this.textToCopy)

        // @ts-expect-error: Property '$tooltip' does not exist on type...
        this.$tooltip(this.successMessage, {
            theme: this.$store.theme,
            timeout: 2000,
        })
    },
})

export default Component
